import { PhotoProvider, PhotoView } from 'react-photo-view';

const images = [
  'https://i.v2ex.co/f0LfsD8xl.jpeg',
  'https://i.v2ex.co/D2dGpLqJl.jpeg',
  'https://i.v2ex.co/fiTeuoy0l.jpeg',
  'https://i.v2ex.co/72p7iHe4l.jpeg',
  'https://i.v2ex.co/Q67gaebUl.jpeg',
  'https://i.v2ex.co/HaSXyfq3l.jpeg',
  'https://i.v2ex.co/leoU4AV5l.jpeg'
];

export default function MyComponent() {
  return (
    <PhotoProvider photoClosable>
      <div className="container">
        {images.map((item, index) => (
          <PhotoView key={index} src={item}>
            <div className='item'>
              <img src={item} alt="" />
            </div>
          </PhotoView>
        ))}
      </div>
    </PhotoProvider>
  );
}